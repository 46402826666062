export default function Sidebar() {
  return (
    <div className="sideSection boxShadow">
      <div className="sideContainer">
        <ul className="sideList">
          <a href="mailto:hey@eluxtre.de">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
              </svg>
            </li>
          </a>
          <a
            href="https://www.instagram.com/h.26vier/"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </li>
          </a>
          <a
            href="https://www.youtube.com/@lucasxtr2804"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM48 368v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H416zM48 240v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H416zM48 112v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zM416 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H416zM160 128v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H192c-17.7 0-32 14.3-32 32zm32 160c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32H192z" />
              </svg>
            </li>
          </a>
          <a
            href="https://fhp.incom.org/profile/14606/projects"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="40 0 580 1300">
                <path
                  d="M135.4,869.6c-55.5,0-96.2-12.2-135.4-62.3l46.1-54.2C73.1,781.5,93.5,791,126,791v-43.3h121.9v120.6L135.4,869.6
	L135.4,869.6z"
                />
                <rect x="329.1" y="749" width="121.9" height="120.6" />
                <polygon points="124.6,0 512,0 512,291.2 419.9,291.2 419.9,116.5 352.2,116.5 352.2,277.6 260.1,277.6 260.1,116.5 124.6,116.5 " />
                <path d="M124.6,562.1h151.7V449.7H124.6V330.5H512v119.2H376.6v112.4H512v120.6H124.6V562.1z" />
                <path
                  d="M124.6,938.6H512v165.2c0,75.9-8.1,109.7-23,132.7c-23,33.9-59.6,50.1-107,50.1c-40.6,1.4-81.3-16.3-107-48.8
	c-19-27.1-28.4-62.3-28.4-121.9v-59.6H124.6C124.6,1056.5,124.6,938.6,124.6,938.6z M337.3,1102.5c-1.4,17.6,2.7,33.9,10.8,48.8
	c8.1,8.1,19,13.5,31.2,12.2c12.2,1.4,23-4.1,31.2-12.2c8.1-8.1,10.8-23,10.8-47.4v-47.4h-84C337.3,1056.5,337.3,1102.5,337.3,1102.5
	z"
                />
              </svg>
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
}
